import {
	EVIDENCES_SET_IMPORTED_EVIDENCES_INFO,
	EVIDENCES_GET_IMPORTED_EVIDENCES_INFO_STATUS,
	EVIDENCES_IMPORT_EVIDENCES,
	EVIDENCES_IMPORT_EVIDENCES_STATUS,
	EVIDENCE_LOG_RESET_FILTERS,
	EVIDENCE_CHANGE_FILTER_FIELD,
	EVIDENCE_RESET_FILTERS,
	EVIDENCE_LOG_EXPORT_TO_CSV_STATUS,
	EVIDENCE_LOG_EXPORT_TO_JSON_STATUS,
	EVIDENCE_SET_EVIDENCE_ACTION,
	EVIDENCE_GET_SINGLE_EVIDENCE_STATUS,
	EVIDENCES_GET_NEW_IMPORTED_EVIDENCES_INFO_STATUS,
	EVIDENCES_SET_NEW_IMPORTED_EVIDENCES_INFO,
	IMPORTED_NEW_EVIDENCE_CHANGE_FILTER_FIELD,
	IMPORTED_EVIDENCE_RESET_FILTERS,
} from '../constants';
import { NONE, LOADING } from 'constants/apiStatuses';
import mergeFilters from '../../helpers/mergeFilters';

const initialState = {
	data: null,
	total: null,
	newGetImportedEvidenceInfoStatus: NONE,
	getImportedEvidenceInfoStatus: NONE,
	importedEvidenceInfo: null,
	importEvidenceStatus: NONE,
	exportEvidenceLogToCSVStatus: NONE,
	exportEvidenceLogToJSONStatus: NONE,
	singleEvidence: null,
	getSingleEvidenceStatus: LOADING,
	evidenceFilters: {
		status: '',
		userName: '',
		consumerId: '',
		fromDate: new Date(2019, 11, 30),
		toDate: new Date(2030, 11, 31),
		action: '',
		evidenceType: '',
	},
	importEvidenceFilters: {
		status: '',
		accountName: '',
	},
};

const evidencesReducer = (state = initialState, action) => {
	switch (action.type) {
		case EVIDENCES_GET_IMPORTED_EVIDENCES_INFO_STATUS: {
			return {
				...state,
				getImportedEvidenceInfoStatus: action.payload,
			};
		}

		case EVIDENCES_GET_NEW_IMPORTED_EVIDENCES_INFO_STATUS: {
			return {
				...state,
				newGetImportedEvidenceInfoStatus: action.payload,
			};
		}

		case EVIDENCES_SET_IMPORTED_EVIDENCES_INFO: {
			return {
				...state,
				importedEvidenceInfo: action.payload,
			};
		}

		case EVIDENCES_SET_NEW_IMPORTED_EVIDENCES_INFO: {
			return {
				...state,
				data: action.payload?.evidences,
				total: action.payload?.total,
			};
		}

		case IMPORTED_NEW_EVIDENCE_CHANGE_FILTER_FIELD: {
			return {
				...state,
				importEvidenceFilters: {
					...state.importEvidenceFilters,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case IMPORTED_EVIDENCE_RESET_FILTERS: {
			return {
				...state,
				importEvidenceFilters: mergeFilters(
					action.payload,
					initialState.importEvidenceFilters
				),
			};
		}

		case EVIDENCES_IMPORT_EVIDENCES_STATUS: {
			return {
				...state,
				importEvidenceStatus: action.payload,
			};
		}

		case EVIDENCES_IMPORT_EVIDENCES: {
			return {
				...state,
				importedEvidenceInfo: state.importedEvidenceInfo.map((evidenceInfo) => {
					return {
						...evidenceInfo,
						doesAccountExist: true,
						isEvidenceForAccountExists: true,
					};
				}),
			};
		}

		case EVIDENCE_LOG_RESET_FILTERS: {
			return {
				...state,
				evidenceFilters: mergeFilters(
					action.payload,
					initialState.evidenceFilters
				),
			};
		}

		case EVIDENCE_CHANGE_FILTER_FIELD: {
			return {
				...state,
				evidenceFilters: {
					...state.evidenceFilters,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case EVIDENCE_RESET_FILTERS: {
			return {
				...state,
				evidenceFilters:
					action.payload === null
						? initialState.evidenceFilters
						: {
								...initialState.evidenceFilters,
								...action.payload,
						  },
			};
		}

		case EVIDENCE_LOG_EXPORT_TO_CSV_STATUS: {
			return {
				...state,
				exportEvidenceLogToCSVStatus: action.payload,
			};
		}

		case EVIDENCE_LOG_EXPORT_TO_JSON_STATUS: {
			return {
				...state,
				exportEvidenceLogToJSONStatus: action.payload,
			};
		}

		case EVIDENCE_SET_EVIDENCE_ACTION: {
			return {
				...state,
				singleEvidence: action.payload,
			};
		}

		case EVIDENCE_GET_SINGLE_EVIDENCE_STATUS: {
			return {
				...state,
				getSingleEvidenceStatus: action.payload,
			};
		}

		default: {
			return state;
		}
	}
};

export default evidencesReducer;
